import { createRouter, createWebHistory } from "vue-router";
import { useAccountStore } from "@/stores/account";
import { useIndexStore } from "@/stores";
import { accountService } from "@/services";
import { useHead } from "@unhead/vue";
import {trackingService} from "@/services/tracking-service";
import {nextTick} from "vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/HomeViewNew.vue"),
      alias: ["/home-3"],
    },
    {
      path: "/search",
      name: "search",
      component: () => import("../views/SearchView.vue"),
    },
    {
      path: "/search/:tag",
      name: "search-tag",
      component: () => import("../views/SearchView.vue"),
      props: true,
    },
    {
      path: "/bookmarks",
      name: "bookmarks",
      component: () => import("../views/BookmarkList.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("../views/RegisterView.vue"),
    },
    {
      path: "/2BiLTmfaWA4XF8mAGwgL",
      name: "secret-register",
      component: () => import("../views/SecretRegisterView.vue"),
    },
    {
      path: "/pay",
      name: "pay",
      component: () => import("../views/PayView.vue"),
    },
    {
      path: "/register-pay",
      name: "register-pay",
      component: () => import("../views/RegisterPayView.vue"),
    },
    {
      path: "/forgotten-password",
      name: "forgotten-password",
      component: () => import("../views/ForgottenPasswordView.vue"),
    },
    {
      path: "/reset-password/:token",
      name: "reset-password",
      component: () => import("../views/ResetPasswordView.vue"),
      props: true,
    },
    {
      path: "/signin",
      name: "login",
      component: () => import("../views/LoginView.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: () => {
        let accountStore = useAccountStore();

        if (accountStore.authenticated) {
          trackingService.clearUserProperties()
          accountService.logout().then(() => {
            router.push("/").catch(() => {});
          });
        } else {
          router.push("/").catch(() => {});
        }
      },
    },
    {
      path: "/subscribe",
      name: "subscriptions",
      component: () => import("../views/PlansView.vue"),
      beforeEnter: () => {
        let accountStore = useAccountStore();

        if (accountStore.authenticated && accountStore.user.subscribed) {
          router.push({ name: "account" }).catch(() => {});
        }
      },
    },
    {
      path: "/welcome",
      name: "welcome",
      component: () => import("../views/WelcomeView.vue"),
    },
    {
      path: "/articles/:slug",
      name: "article",
      component: () => import("../views/ArticleView.vue"),
      props: true,
      beforeEnter: () => {
        let accountStore = useAccountStore();

        if (accountStore.authenticated && !accountStore.user.email_verified_at) {
          router
            .push({ name: "verify" })
            .then(() => {
              router.go();
            })
            .catch(() => {});
        }
      },
    },
    {
      path: "/author/:slug",
      name: "person",
      component: () => import("../views/PersonView.vue"),
      props: true,
    },
    {
      path: "/:category(technology|culture|perspectives|company-building)",
      alias: ["/categories/:category"],
      name: "category",
      component: () => import("../views/CategoryView.vue"),
      props: true,
    },
    {
      path: "/:slug",
      name: "content",
      alias: ["/content/:slug"],
      component: () => import("../views/ContentView.vue"),
      props: true,
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/account",
      name: "account",
      component: () => import("../views/AccountView.vue"),
    },
    {
      path: "/newsletter-preferences",
      name: "newsletters",
      component: () => import("../views/NewsletterSubscriptions.vue"),
    },
    {
      path: "/newsletter-preferences/uk-2.0",
      name: "newsletters-uk-two",
      component: () => import("../views/Newsletters/UkTwo.vue"),
    },
    {
      path: "/newsletter-preferences/anatomy-of-a-founder",
      name: "newsletters-anatomy",
      component: () => import("../views/Newsletters/Anatomy.vue"),
    },
    {
      path: "/newsletter-preferences/makers-memo",
      name: "newsletters-makers",
      component: () => import("../views/Newsletters/Makers.vue"),
    },
    {
      path: "/newsletter-preferences/weekly-review",
      name: "newsletters-weekly-review",
      component: () => import("../views/Newsletters/WeeklyReview.vue"),
    },
    {
      path: "/newsletter-preferences/mid-week-drop",
      name: "newsletters-mid-week-drop",
      component: () => import("../views/Newsletters/MidWeekDrop.vue"),
    },
    {
      path: "/newsletter-preferences/digital-state-dispatch",
      name: "newsletters-digital-state-dispatch",
      component: () => import("../views/Newsletters/DigitalStateDispatch.vue"),
    },
    {
      path: "/newsletter-signup/anatomy-of-a-founder",
      name: "anatomy-of-a-founder",
      component: () => import("../views/AnatomyForm.vue"),
    },
    {
      path: "/press",
      name: "press",
      component: () => import("../views/PressView.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("../views/ContactView.vue"),
    },
    {
      path: "/corporate-subscriptions",
      name: "corporate-subscriptions",
      component: () => import("../views/ContactBusinessView.vue"),
    },
    {
      path: "/meet-the-team",
      name: "team",
      component: () => import("../views/TeamView.vue"),
      beforeEnter: () => {
        if (typeof pSUPERFLY !== "undefined") {
          pSUPERFLY.virtualPage({
            sections: "Meet the team",
            authors: "",
            path: "/meet-the-team",
            title: "Digital Frontier | Meet the team",
          });
        }
      },
    },
    {
      path: "/podcasts",
      name: "podcast-index",
      component: () => import("../views/PodcastHomepageView.vue"),
      alias: ["/podcasts/podcast-homepage"],
    },
    {
      path: "/podcasts/:series",
      name: "podcast-series",
      component: () => import("../views/PodcastSeriesView.vue"),
      props: true,
    },
    {
      path: "/podcasts/:series/:slug",
      name: "podcast",
      component: () => import("../views/PodcastPageView.vue"),
      props: true,
    },
    {
      path: "/verify",
      name: "verify",
      component: () => import("../views/VerifyView.vue"),
    },
    {
      path: "/verified",
      name: "verified",
      component: () => import("../views/VerifiedView.vue"),
    },
    {
      path: "/free-magazine",
      name: "free-magazine",
      component: () => import("../views/FreeMagazineView.vue"),
    },

    {
      path: "/rabbit-hole",
      name: "rabbit-hole",
      component: () => import("../views/RabbitHoleView.vue"),
    },
    {
      path: "/rabbit-hole/issue-003",
      name: "rabbit-hole-issue-003",
      component: () => import("../views/RabbitHoleIssueView.vue"),
    },
    {
      path: "/rabbit-hole/issue-003/:slug",
      name: "rabbit-hole-issue-003-article",
      component: () => import("../views/RabbitHoleArticleView.vue"),
    },
    {
      path: "/rabbit-hole/open-tabs",
      name: "rabbit-hole-open-tabs",
      component: () => import("../views/OpenTabsView.vue"),
    },
    {
      path: "/404",
      name: "404",
      component: () => import("../views/404View.vue"),
    },
    { path: "/:pathMatch(.*)*", redirect: "/404" },
  ],
});

router.beforeEach((to, from) => {
  useHead({
    link: {
      href: "https://digitalfrontier.com" + to.fullPath,
      rel: "canonical",
    },
  });

  let indexStore = useIndexStore();

  if (from.name) {
    indexStore.newPage();
  }
});

router.afterEach(async (to, from) => {
  if (to.path === from.path) {
    window.scrollTo(0, 0);
  }

  // List of routes to exclude
  const excludedRoutes = ['article', 'person', 'category', 'podcast-series', 'podcast'];

  // If the route is in the excludedRoutes list, do not fire the event
  if (excludedRoutes.includes(to.name)) {
    return; // Skip the event firing for excluded routes
  }

  // Wait for the document title to be updated by useSeoMeta
  await nextTick();

  gtag('event', 'page_view', {
    page_title: document.title,   // Send the title of the page
    page_location: window.location.href,  // The full URL of the page
    page_path: to.fullPath,
  });
});

export default router;
