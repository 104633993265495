<script setup>
  import { computed } from "vue";
  import Date from "./Date.vue";
  import Authors from "@/components/Authors.vue";
  import { useDisplay } from "vuetify";
  const props = defineProps({ blok: Object });
  const { name } = useDisplay();

  const sliderHeight = computed(() => {
    switch (name.value) {
      case "xs":
        return "170vw";
      case "sm":
        return "80vw";
      case "md":
        return "40vw";
      case "lg":
        return "540px";
      case "xl":
        return "580px";
      case "xxl":
        return "580px";
    }
  });

  // import function to register Swiper custom elements
  import { register } from "swiper/element/bundle";
  // register Swiper custom elements
  register();
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
</script>

<template>
  <section>
    <v-container fluid>
      <v-row class="featured-stories-slider">
        <v-col>
          <!-- Slider main container -->
          <swiper-container
            class="swiper"
            :pagination="{
              clickable: true,
            }"
            :navigation="false"
            :spaceBetween="30"
            :centeredSlides="true"
            clickable="true"
            :autoplay="{
              delay: 5000,
              disableOnInteraction: true,
            }"
            :loop="true"
            :slidesPerView="'auto'"
            :grabCursor="true"
            :effect="'creative'"
            :creativeEffect="{
              prev: {
                shadow: false,
                translate: [0, 0, -400],
              },
              next: {
                shadow: false,
                translate: ['100%', 0, 0],
              },
            }"
          >
            <swiper-slide v-for="story in blok">
              <div class="slider-item">
                <div class="slider-item__bg">
                  <router-link
                    :to="{
                      name: 'article',
                      params: { slug: story.slug },
                    }"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="7"
                      >
                        <div class="slider-item__image">
                          <div class="d-block d-md-none aspect-ratio-box-3x4">
                            <v-img
                              v-if="story.content.header[0].background_image_mobile.filename"
                              aspect-ratio="4/6"
                              cover
                              :src="story.content.header[0].background_image_mobile.filename + '/m/400x600/'"
                              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              :alt="story.content.header[0].background_image_mobile.alt"
                            ></v-img>
                            <v-img
                              v-else
                              aspect-ratio="4/6"
                              cover
                              :src="
                                story.content.header[0].background_image.filename +
                                '/m/400x600/filters:focal(' +
                                story.content.header[0].background_image.focus +
                                ')'
                              "
                              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              :alt="story.content.header[0].background_image.alt"
                            ></v-img>
                          </div>
                          <div class="d-none d-md-block aspect-ratio-box-16x9">
                            <v-img
                              aspect-ratio="16/9"
                              cover
                              :src="
                                story.content.header[0].background_image.filename +
                                '/m/896x504/filters:focal(' +
                                story.content.header[0].background_image.focus +
                                ')'
                              "
                              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              :alt="story.content.header[0].background_image.alt"
                              class="shadow-sm"
                            ></v-img>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="1"
                        class="d-none d-md-flex"
                        ><div class="slider-item__content w-100"></div
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <div class="slider-item__content">
                          <div>
                            <authors :reporters="story.content.reporters" />
                            <div class="heading-container">
                              <h2 class="pl-4 heading-highlight smaller-title-section right-border mb-0">
                                <span>{{ story.name }}</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </router-link>
                </div>
              </div>
            </swiper-slide>
          </swiper-container>

          <!-- <v-carousel
            hide-delimiter-background
            :cycle="true"
            color="black"
            :show-arrows="false"
            :height="sliderHeight"
          >
            <v-carousel-item
              v-for="story in blok"
              eager
              :height="sliderHeight"
            >
              <div class="slider-item">
                <div class="slider-item__bg">
                  <router-link
                    :to="{
                      name: 'article',
                      params: { slug: story.slug },
                    }"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="7"
                      >
                        <div class="slider-item__image">
                          <div class="d-block d-md-none aspect-ratio-box-3x4">
                            <v-img
                              v-if="story.content.header[0].background_image_mobile.filename"
                              aspect-ratio="4/6"
                              cover
                              :src="story.content.header[0].background_image_mobile.filename + '/m/400x600/'"
                              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              :alt="story.content.header[0].background_image_mobile.alt"
                            ></v-img>
                            <v-img
                              v-else
                              aspect-ratio="4/6"
                              cover
                              :src="
                                story.content.header[0].background_image.filename +
                                '/m/400x600/filters:focal(' +
                                story.content.header[0].background_image.focus +
                                ')'
                              "
                              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              :alt="story.content.header[0].background_image.alt"
                            ></v-img>
                          </div>
                          <div class="d-none d-md-block aspect-ratio-box-16x9">
                            <v-img
                              aspect-ratio="16/9"
                              cover
                              :src="
                                story.content.header[0].background_image.filename +
                                '/m/896x504/filters:focal(' +
                                story.content.header[0].background_image.focus +
                                ')'
                              "
                              lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              :alt="story.content.header[0].background_image.alt"
                            ></v-img>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="1"
                        class="d-none d-md-flex"
                        ><div class="slider-item__content w-100"></div
                      ></v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <div class="slider-item__content">
                          <authors :reporters="story.content.reporters" />
                          <div class="heading-container">
                            <h2 class="pl-4 heading-highlight smaller-title-section right-border mb-0">
                              <span>{{ story.name }}</span>
                            </h2>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </router-link>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel> -->
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style lang="scss" scoped>
  $sliderMargin: 24px;

  .v-container,
  .v-row,
  div[class*="v-col"] {
    margin: 0;
    padding: 0;
  }

  .v-container {
    margin: 0 auto;
  }

  .featured-stories-slider {
    margin-top: 119px;
    margin-bottom: 67px;

    a:hover {
      text-decoration: none !important;
    }
    @media (width <= 599px) {
      margin: 27px -16px 48px;
    }
  }

  .slider-item {
    // height: 100%;
    // padding-bottom: 20px;
    background-color: #fff;

    @media (width >= 600px) {
      background-color: transparent;
      // padding-bottom: 50px;
    }

    &:after {
      content: "";
      height: 50px;
      background: var(--main-bg-color);
      width: 100%;
      display: block;
      z-index: 30;
      position: relative;
    }

    &__image {
      position: relative;
      padding-left: 16px;
      background-color: var(--card-highlight-color);
      padding-bottom: $sliderMargin;
      max-width: 79%;

      @media (width >= 600px) {
        padding-left: $sliderMargin;
        padding-right: calc($sliderMargin/2);
        max-width: 100%;
      }

      &:before {
        content: "";
        height: 16px;
        background: var(--main-bg-color);
        width: 130%;
        position: absolute;
        left: 0;
        top: 0;

        @media (width >= 600px) {
          width: 100%;
          height: $sliderMargin;
        }
      }
    }

    &__content {
      background-color: var(--card-highlight-color);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 30px;
      padding-left: 16px;
      position: relative;
      min-height: 160px;

      @media (width >= 600px) {
        // padding-top: $sliderMargin + 25px;
        justify-content: start;
        min-height: 0;

        &:before {
          content: "";
          height: $sliderMargin;
          background: var(--main-bg-color);
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      @media (width >= 960px) {
        padding-left: 0;
        // padding-top: $sliderMargin + 48px;
      }
      // @media (width >= 1280px) {
      //   padding-top: $sliderMargin + 72px;
      // }

      > div {
        @media (width >= 600px) {
          padding-top: $sliderMargin + 25px;
        }
        @media (width >= 960px) {
          padding-left: 0;
          padding-top: $sliderMargin + 48px;
        }
        @media (width >= 1280px) {
          padding-top: $sliderMargin + 72px;
        }
      }
    }
  }

  .heading-container {
    margin-right: 24px;
    margin-top: 40px;
    @media (width <= 599px) {
      min-height: 100px;
    }
  }

  .heading-highlight {
    border-left: 2px solid #000;
    font-weight: 400;
  }

  .v-carousel__controls {
    color: black;
    // bottom: 0;

    .v-carousel__controls__item .v-icon {
      opacity: 0.5;
    }
    .v-btn__overlay,
    .v-btn__underlay {
      width: auto;
      height: auto;
    }

    .v-ripple__container {
      display: none;
    }
  }

  .v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height));
    height: calc(var(--v-btn-height));

    &.v-btn--active.v-carousel__controls__item .v-icon {
      opacity: 1;
    }
  }

  .swiper {
    width: 100%;
  }

  .shadow-sm {
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
</style>
