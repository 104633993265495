<script setup>
  import { ref } from "vue";
  import { useRoute } from "vue-router";
  const route = useRoute();
  const props = defineProps({
    stories: Object,
    isReadingList: Boolean,
    favourites: Object,
    isFavourite: {
      type: Boolean,
      default: false,
    },
  });

  import listTile from "./ListTile.vue";

  const emit = defineEmits(["remove-bookmark"]);

  const handleRemoveBookmark = (uuid) => {
    emit("remove-bookmark", uuid);
  };

  // const handleRemoveBookmark = async (uuid) => {
  //   console.log("handleRemoveBookmark", uuid);
  //   if (route.name === "bookmarks") {
  //     props.favourites.value.stories = props.favourites.value.stories.filter((story) => story.uuid !== uuid);
  //   } else {
  //     props.stories = props.stories.filter((story) => story.uuid !== uuid);
  //   }
  //   componentKey.value++;
  //   // await loadFavourites();
  // };

  const isFavourite = (uuid) => {
    if (props.isReadingList) {
      return true;
    } else {
      return props?.favourites?.stories?.some((story) => story.uuid === uuid);
    }
  };
</script>

<template>
  <div>
    <v-row>
      <v-col class="v-col-12">
        <div
          v-for="story in stories"
          :key="story._uid"
        >
          <list-tile
            :story="story"
            :isFavourite="isFavourite(story.uuid)"
            @remove-bookmark="handleRemoveBookmark"
          ></list-tile>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
