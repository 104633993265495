<script setup>
  import { onMounted, onUnmounted, ref } from "vue";
  import router from "@/router";
  import { useAccountStore } from "@/stores/account";
  import bookmark from "./Bookmark.vue";
  import { useShare } from "@vueuse/core";
  import { useGtm } from "@gtm-support/vue-gtm";

  const { share, isSupported } = useShare();
  const gtm = useGtm();

  const props = defineProps({
    blok: Object,
    first_published_at: String,
    showPaywall: Boolean,
    tags: Array,
    name: String,
    isAllowed: Boolean,
    uuid: String,
    category: String,
    isFavourite: Boolean,
    showTagsAndBanner: Boolean,
  });

  import { computed } from "vue";

  import magazineBanner from "@/assets/Display_Banner_Issue003_1517x188_V2.jpg";
  import magazineMpu from "@/assets/Display_Banner_MPU_Issue003_600x500_NEW.jpg";
  import Authors from "@/components/Authors.vue";
  import Date from "@/components/Date.vue";
  import Bookmark from "@/components/Bookmark.vue";
  import Share from "@/components/Share.vue";
  import { useRoute } from "vue-router";
  import { trackingService } from "@/services/tracking-service";

  const route = useRoute();
  const mode = import.meta.env.MODE;

  const accountStore = useAccountStore();

  const userId = accountStore.user.id;
  const articleId = props.uuid;
  const favourites = ref();
  const localIsFavourite = ref();
  // const url = window.location.href;
  const url = "https://digitalfrontier.com" + route.fullPath;

  const search = (tag) => {
    router.push({ name: "search-tag", params: { tag: tag } });
  };

  const adsenseClientId = () => {
    return import.meta.env.VITE_ADSENSE_CLIENT_ID;
  };

  const adsenseAdSlotId = () => {
    return import.meta.env.VITE_ADSENSE_AD_SLOT_ID;
  };

  const background = computed(() => {
    if (props.blok.dark) {
      return "background-dark";
    }

    return "background-light";
  });

  const shouldShowTeaser = computed(() => {
    if (document.referrer === "https://app.storyblok.com/") {
      return false;
    }
    return !!props.showPaywall;
  });

  const scrollPercentage = ref(0);
  const scrolledTo = ref(0);

  const updateScrollPercentage = () => {
    const scrollY = window.scrollY;
    const viewportHeight = window.innerHeight;

    const articleContent = document.querySelector(".article-page");

    // Get its height
    const articleHeight = articleContent.offsetHeight;

    scrollPercentage.value = (scrollY / (articleHeight - viewportHeight)) * 100;

    if (scrollPercentage.value >= 10 && Math.round(scrollPercentage.value) > scrolledTo.value && isDivisibleBy10(scrollPercentage.value)) {
      if (trackingService.isGtagEnabled()) {
        gtag("event", "article_scroll", {
          action: "scroll",
          label: "Article Scroll",
          value: Math.round(scrollPercentage.value),
          percent_scrolled: Math.round(scrollPercentage.value),
          article_category: props.category,
          content_group: "article",
        });
      }

      scrolledTo.value = Math.round(scrollPercentage.value);
    }
  };

  function isDivisibleBy10(value) {
    return Math.round(value) % 10 === 0;
  }

  function startShare() {
    share({
      title: props.name,
      text: props.blok.header[0].standfirst,
      url: url,
    });
  }

  onMounted(() => {
    window.addEventListener("scroll", updateScrollPercentage);
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", updateScrollPercentage);
  });
</script>

<template>
  <!--  <v-container :fluid="true">-->

  <div class="article-page">
    <div class="sticky">
      <v-progress-linear
        v-if="scrollPercentage.toFixed(2) > 0"
        :model-value="scrollPercentage.toFixed(2)"
        color="primary"
        :height="16"
      ></v-progress-linear>
    </div>

    <div v-editable="blok">
      <component
        is="header"
        :blok="blok.header"
        :name="name"
        :reporters="blok.reporters"
        :dark="blok.dark"
        :first_published_at="first_published_at"
        :category="blok.category"
        :tags="tags"
        :opEd="blok.op_ed"
        :isFavourite="isFavourite"
        :uuid="uuid"
        :centralText="blok.central_text || !('central_text' in blok)"
      ></component>
    </div>

    <v-container class="outer-container">
      <v-row>
        <v-col
          v-if="blok.central_text || !('central_text' in blok)"
          :class="'v-col-12 v-col-md-2 d-none d-md-block'"
        >
          <div
            v-if="category === 'perspectives' && (!blok.header[0].headline_over_image || !blok.header[0].full_width)"
            class="biog-container"
          >
            <!--                :srcset="blok.reporters[0].content.profile_picture.filename + '/m/300x450 300w,' + blok.reporters[0].content.profile_picture.filename + '/m/480x720 480w,' + blok.reporters[0].content.profile_picture.filename + '/m/640x960 640w,' + blok.reporters[0].content.profile_picture.filename + '/m/960x1440 960w,'  + blok.reporters[0].content.profile_picture.filename + '/m/1280x1920 1280w'"-->
            <!--                sizes="(max-width: 600px) 300px, (min-width: 600px) 480px, (min-width: 960px) 640px, (min-width: 1280px) 960px, 1280px"-->
            <v-img
              aspect-ratio="3/4"
              class="person"
              :src="blok.reporters[0].content.profile_picture.filename + '/m/300x400'"
              :alt="blok.reporters[0].name"
              contain
              width="116"
            />
            <p class="biog d-none d-sm-block mt-6 mb-0 mr-6">{{ blok.reporters[0].content.description }}</p>
          </div>
        </v-col>
        <v-col
          v-if="!blok.central_text && 'central_text' in blok"
          :class="'v-col-12 v-col-md-4 d-none d-md-block'"
        >
          <div
            v-if="!blok.header[0].full_width || blok.header[0].headline_over_image"
            class="heading-container"
          >
            <h1 v-if="!blok.header[0].headline_over_image">{{ name }}</h1>

            <p class="standfirst">{{ blok.header[0].standfirst }}</p>

            <authors
              class="mb-0"
              :reporters="blok.reporters"
            />

            <Date
              :date="first_published_at"
              class="mb-6"
            />

            <div
              v-if="category === 'perspectives'"
              class="d-flex flex-row justify-start align-top mb-3"
            >
              <v-img
                aspect-ratio="3/4"
                class="person"
                :src="blok.reporters[0].content.profile_picture.filename + '/m/300x400'"
                :alt="blok.reporters[0].name"
                contain
                width="116"
              />
              <p class="biog mt-0 mb-0 pl-6">{{ blok.reporters[0].content.description }}</p>
            </div>

            <div class="d-flex flex-row bookmark-container">
              <bookmark
                :isFavourite="props.isFavourite"
                :uuid="props.uuid"
              />
              <share
                :name="blok.name"
                :standfirst="blok.header[0].standfirst"
              />
            </div>
          </div>
        </v-col>
        <v-col :class="!blok.central_text && 'central_text' in blok ? 'v-col-12 offset-md-1 v-col-md-7' : 'v-col-md-8'">
          <div
            v-if="!blok.header[0].full_width"
            class="d-block d-md-none heading-container"
          >
            <p class="standfirst">{{ blok.header[0].standfirst }}</p>

            <authors
              class="mb-0"
              :reporters="blok.reporters"
            />

            <Date
              :date="first_published_at"
              class="mb-6"
            />

            <div
              v-if="category === 'perspectives'"
              class="d-flex flex-row justify-start align-start pb-3"
            >
              <v-img
                aspect-ratio="3/4"
                class="person"
                :src="blok.reporters[0].content.profile_picture.filename + '/m/300x400'"
                :alt="blok.reporters[0].name"
                contain
                width="116"
              />
              <p class="biog mt-0 mb-0 mr-6 pl-6">{{ blok.reporters[0].content.description }}</p>
            </div>

            <div class="d-flex flex-row bookmark-container">
              <bookmark
                :isFavourite="isFavourite"
                :uuid="uuid"
              />
              <share
                :name="blok.name"
                :standfirst="blok.header[0].standfirst"
              />
            </div>
          </div>
          <div
            v-if="blok.header[0].full_width && category === 'perspectives'"
            class="d-block d-md-none d-flex flex-row justify-start align-top mb-3 pt-3 pb-3"
          >
            <div class="biog-container">
              <v-img
                aspect-ratio="3/4"
                class="person"
                :src="blok.reporters[0].content.profile_picture.filename + '/m/300x400'"
                :alt="blok.reporters[0].name"
                contain
                width="116"
              />
            </div>
            <p class="biog mt-0 mb-0 pl-6">{{ blok.reporters[0].content.description }}</p>
          </div>

          <div
            v-if="blok.intro_text"
            v-editable="blok"
          >
            <component
              is="intro_text"
              :blok="blok.intro_text"
            ></component>
          </div>

          <div
            v-if="blok.content"
            v-editable="blok"
            :class="!isAllowed ? 'fade-out' : ''"
          >
            <div class="content-inner">
              <StoryblokComponent
                v-if="blok.content.length > 0"
                :blok="blok.content[0]"
                :index="0"
                :opEd="blok.op_ed"
                :key="blok.content[0]._uid"
                :person="blok.reporters.length > 0 ? blok.reporters[0] : null"
                :centralText="blok.central_text || !('central_text' in blok)"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            v-if="blok.content"
            v-editable="blok"
            :class="!isAllowed ? 'content-container fade-out' : 'content-container'"
          >
            <div class="content-inner">
              <StoryblokComponent
                v-for="(inblok, index) in blok.content.slice(1)"
                :blok="inblok"
                :index="index + 1"
                :opEd="blok.op_ed"
                :key="inblok._uid"
                :person="blok.reporters.length > 0 ? blok.reporters[0] : null"
                :centralText="blok.central_text || !('central_text' in blok)"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="showTagsAndBanner">
        <v-col>
          <v-container fluid>
            <v-row v-if="tags.length > 0">
              <v-col
                :class="
                  !blok.central_text && 'central_text' in blok ?
                    'v-col-12 offset-0 v-col-md-7 offset-md-5 pl-0 pr-0 pl-md-3 pr-md-3'
                  : 'v-col-12 offset-0 v-col-md-10 offset-md-2 pl-0 pr-0'
                "
              >
                <router-link
                  v-if="category === 'technology'"
                  :to="{ name: 'category', params: { category: 'technology' } }"
                >
                  <v-icon
                    size="small"
                    class="mr-4"
                    ><img
                      src="@/assets/icons/technology_icon.svg"
                      alt="Technology Icon"
                      width="30"
                      height="30"
                  /></v-icon>
                </router-link>
                <router-link
                  v-else-if="category === 'culture'"
                  :to="{ name: 'category', params: { category: 'culture' } }"
                >
                  <v-icon
                    size="small"
                    class="mr-4"
                    ><img
                      src="@/assets/icons/culture_icon.svg"
                      alt="Technology Icon"
                      width="30"
                      height="30"
                  /></v-icon>
                </router-link>
                <router-link
                  v-else-if="category === 'perspectives'"
                  :to="{ name: 'category', params: { category: 'perspectives' } }"
                >
                  <v-icon
                    size="small"
                    class="mr-4"
                    ><img
                      src="@/assets/icons/perspectives_icon.svg"
                      alt="Technology Icon"
                      width="30"
                      height="30"
                  /></v-icon>
                </router-link>
                <router-link
                  v-else-if="category === 'company-building'"
                  :to="{ name: 'category', params: { category: 'company-building' } }"
                >
                  <v-icon
                    size="small"
                    class="mr-4"
                    ><img
                      src="@/assets/icons/company_building_icon.svg"
                      alt="Technology Icon"
                      width="30"
                      height="30"
                  /></v-icon>
                </router-link>
                <v-btn
                  v-for="tag in tags"
                  variant="flat"
                  rounded
                  color="#eee"
                  size="small"
                  class="tag text-uppercase mr-3"
                  @click="search(tag)"
                  >{{ tag }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :class="
                  !blok.central_text && 'central_text' in blok ?
                    'v-col-12 offset-0 v-col-md-7 offset-md-5 mt-0 pb-0 pl-0 pr-0 pl-md-3 pr-md-3'
                  : 'v-col-12 offset-0 v-col-md-8 offset-md-2 pl-0 pr-0'
                "
              >
                <div class="text-center">
                  <a
                    href="https://digitalfrontier.newsstand.co.uk"
                    target="_blank"
                  >
                    <v-img
                      class="d-none d-sm-block mb-10 mt-4"
                      width="100%"
                      :src="magazineBanner"
                    />
                    <v-img
                      class="d-block d-sm-none mb-4"
                      width="100%"
                      :src="magazineMpu"
                    />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
  .biog-container {
    border-left: solid 1px black;
    padding-left: 24px;
  }

  .biog {
    font-size: 0.82rem;
    line-height: 1.2rem;
    font-family: "SaansMonoBold", sans-serif;
    font-weight: 600;
    max-width: 50vw;
  }

  .bookmark-container {
    margin-left: -6px;
  }

  .article-page {
    margin-top: 24px;
    .sticky {
      position: fixed;
      bottom: 0; /* Distance from the top of the viewport */
      left: 0;
      right: 0;
      z-index: 5000; /* Optional: Ensures it appears above other content */
      background-color: white; /* Optional: Prevents overlap transparency */
    }

    .heading-container {
      border-left: solid 2px #000;
      padding-left: 20px;
      h1 {
        font-family: "SaansSemiMonoBold", sans-serif !important;
        font-size: 1.9rem;
        font-weight: 600;
        margin-bottom: 24px;
      }
      p.standfirst {
        font-family: "SaansSemiMonoRegular", sans-serif !important;
        font-size: 1.375rem;
        line-height: 1.75rem;
        margin-bottom: 24px;
      }
      @media only screen and (max-width: 959px) {
        margin-bottom: 34px;
      }
    }

    .fade-out {
      // margin-bottom: 120px;
      .v-container {
        position: relative;
      }
      @media only screen and (min-width: 600px) {
        // margin-bottom: 40px;
      }
    }

    .fade-out .v-container:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(rgba(255, 255, 255, 0) 50%, #f4f2ee);
    }

    //.full-width-quote {
    //  background-color: #333;
    //  color: rgb(var(--v-theme-background));
    //}

    //.quote-marks {
    //  font-size: 14em;
    //  line-height: 1.3em;
    //  font-family: "DepartureMonoRegular", sans-serif !important;
    //}
    //
    //.quote-end {
    //  rotate: 180deg;
    //  margin-bottom: -20px;
    //}

    .quote-credit {
      font-family: "SaansSemiMonoMedium", sans-serif !important;
      font-size: 1em;
      padding-bottom: 44px;
      margin-top: 0.75rem;
      @media only screen and (max-width: 599px) {
        margin-top: 0;
      }
    }

    .background-light .full-width-quote {
      background-color: #333;
      color: #fff;
    }

    .highlight {
      color: v-bind("highlightColour");
    }

    h2,
    h3 {
      font-family: "SaansSemiMonoBold", sans-serif !important;
      font-weight: 600;
      margin-bottom: 0.3em;
    }

    blockquote {
      border-left: solid 3px lightgrey;
      padding-left: 1em;
      font-style: italic;
    }

    @media only screen and (max-width: 599px) {
      .body-text {
        font-size: 1rem;
        margin-bottom: 0;
      }
      h2 {
        font-size: 1.2em;
      }
      h3 {
        font-size: 1.2em;
      }
      .full-width-quote .quote-text {
        font-size: 1.2em;
        padding-top: 20px;
        line-height: 1.2em;
      }
      .quote-marks {
        font-size: 3.5em;
        line-height: 1.4em;
      }
      .quote-credit {
        font-size: 0.8em;
        padding-bottom: 10px;
      }
      .copyright {
        font-size: 0.8em;
      }
    }

    @media only screen and (min-width: 600px) {
      .body-text {
        font-size: 1em;
      }
      h2 {
        font-size: 1.8em;
      }
      h3 {
        font-size: 1.2em;
      }
      .full-width-quote .quote-text {
        font-size: 1.9em;
        padding-top: 30px;
        line-height: 1.2em;
      }
      .quote-marks {
        font-size: 8em;
        line-height: 1.2em;
      }
      .quote-credit {
        font-size: 1em;
        padding-bottom: 20px;
      }
    }
    @media only screen and (max-width: 959px) {
      margin-top: 0;
    }
    @media only screen and (min-width: 960px) {
      .body-text {
        font-size: 1rem;
      }
      h2 {
        display: inline-block;
        font-size: 1.6rem;
        margin: 24px 0 16px 0 !important;
      }
    }
    .v-fab,
    .v-overlay-container {
      .v-btn.v-btn--density-default,
      button {
        display: grid;
        height: auto !important;
        box-shadow: none;
        width: auto !important;
      }
    }

    .v-overlay__content {
      padding-left: 1.2rem;
    }

    .outer-container {
      min-height: 100vh;
      @media only screen and (max-width: 959px) {
        margin-top: 0 !important;
        padding-top: 8px !important;
      }
    }
  }
</style>
