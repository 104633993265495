<script setup>
  import logo from "@/assets/df-logo-black.png";
  import df from "@/assets/df.png";
  import { useAccountStore } from "@/stores/account";
  import axios from "axios";
  import router from "@/router";
  import { useRoute } from "vue-router";
  const route = useRoute();
  const accountStore = useAccountStore();

  import { ref, onMounted, onUnmounted, computed } from "vue";
  import { useHead, useSeoMeta } from "@unhead/vue";

  import Foot from "@/components/Foot.vue";

  const shouldShowFooter = computed(() => {
    if (document.referrer === "https://app.storyblok.com/") {
      return true;
    } else if (router.currentRoute.value.name === "article") {
      return false;
    } else {
      return true;
    }
  });

  // Dynamic style binding
  const appBarStyle = computed(() => ({
    transform: hideAppBar.value ? "translateY(-100%)" : "translateY(0)",
    transition: "transform 0.3s ease-in-out",
  }));

  const term = ref("");
  const drawer = ref(false);
  const searchDialog = ref(false);
  const hideAppBar = ref(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    // Hide the app bar if the user has scrolled to the bottom
    hideAppBar.value = scrollPosition >= scrollHeight;
  };

  const onSearch = () => {
    if (term.value) {
      router.push("/search?term=" + term.value);
    }

    searchDialog.value = false;
    term.value = "";
  };

  const goToSearch = () => {
    drawer.value = false;
    router.push("/search");
  };

  import { useIndexStore } from "@/stores";
  import {trackingService} from "@/services/tracking-service";

  const checkForNewVersion = async () => {
    await fetchVersion();

    if (compareVersions(useIndexStore().release, latestVersion.value)) {
      console.log("is new version");
      useIndexStore().setRelease(latestVersion.value);
      // this.reload('An newer version of this website is available.')
      window.location.reload();
    }
  };

  function compareVersions(versionA, versionB) {
    // Split the version strings into arrays of numbers
    const partsA = versionA.toString().split(".").map(Number);
    const partsB = versionB.toString().split(".").map(Number);

    // Compare each part
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const numA = partsA[i] || 0; // Use 0 if the version has fewer parts
      const numB = partsB[i] || 0;

      if (numA > numB) {
        return false;
      } else if (numA < numB) {
        return true;
      }
    }

    return false;
  }

  const latestVersion = ref("");

  // Function to fetch the version from package.json
  const fetchVersion = async () => {
    const fetchWithTimeout = (url, options, timeout = 5000) => {
      return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
          reject(new Error("Request timed out"));
        }, timeout);

        fetch(url, options)
          .then((response) => {
            clearTimeout(timer);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timer);
            reject(error);
          });
      });
    };

    try {
      const response = await fetchWithTimeout(
        `/version.json?timestamp=${new Date().getTime()}`,
        {},
        5000 // set your timeout in milliseconds
      );

      const data = await response.json();
      console.log("version json: " + data);
      latestVersion.value = data.version; // Update the current version
    } catch (error) {
      if (error.message === "Request timed out") {
        console.warn("Fetch version timed out");
      } else {
        console.error("Error fetching version:", error);
      }
    }
  };

  onMounted(() => {
    window.addEventListener("scroll", handleScroll);

    if (import.meta.env.PROD) {
      // Start the interval when the component is mounted
      const intervalId = setInterval(() => {
        checkForNewVersion(); // Call the function every 10 seconds
      }, 10000);

      // Clear the interval when the component is unmounted to avoid memory leaks
      onUnmounted(() => {
        clearInterval(intervalId);
      });
    }

    trackingService.checkConsentChange(accountStore.user.id ?? null)

  });

  onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
  });

  const isRabbitHole = computed(() => {
    if (route.matched.some(({ path }) => path.startsWith("/rabbit-hole"))) {
      return true;
    } else {
      return false;
    }
  });
</script>

<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      location="right"
      :temporary="true"
    >
      <v-list
        aria-label="Menu items"
        density="compact"
      >
        <v-list-item
          :to="{ name: 'category', params: { category: 'technology' } }"
          @click="drawer = false"
          class="ml-2"
          role="option"
        >
          Technology
        </v-list-item>
        <v-list-item
          :to="{ name: 'category', params: { category: 'culture' } }"
          @click="drawer = false"
          class="ml-2"
          role="option"
        >
          Culture
        </v-list-item>
        <v-list-item
          :to="{ name: 'category', params: { category: 'perspectives' } }"
          @click="drawer = false"
          class="ml-2"
          role="option"
        >
          Perspectives
        </v-list-item>
        <v-list-item
          :to="{ name: 'category', params: { category: 'company-building' } }"
          @click="drawer = false"
          class="ml-2"
          role="option"
        >
          Company building
        </v-list-item>
      </v-list>

      <v-divider
        class="border-opacity-100 ml-6 mr-6 mt-4 mb-3"
        style="height: 40px"
        color="#bbb"
      ></v-divider>

      <v-list
        aria-label="Menu items"
        density="compact"
        class="ml-2"
      >
        <v-list-item
          href="https://digitalfrontier.newsstand.co.uk"
          target="_blank"
          @click="drawer = false"
          role="option"
        >
          Magazine
        </v-list-item>
        <v-list-item
          :to="{ name: 'podcast-index' }"
          @click="drawer = false"
          role="option"
        >
          Podcasts
        </v-list-item>
        <v-list-item
          :to="{ name: 'newsletters' }"
          @click="drawer = false"
          role="option"
        >
          Newsletters
        </v-list-item>

        <div v-if="accountStore.authenticated">
          <v-list-item
            :to="{ name: 'account' }"
            @click="drawer = false"
            role="option"
          >
            Account
          </v-list-item>
          <v-list-item
            :to="{ name: 'bookmarks' }"
            @click="drawer = false"
            role="option"
          >
            Bookmarks
          </v-list-item>
          <v-list-item
            :to="{ name: 'newsletters' }"
            @click="drawer = false"
            role="option"
          >
            Newsletters
          </v-list-item>
          <v-list-item
            :to="{ name: 'subscriptions' }"
            v-if="!accountStore.user.subscribed"
            @click="drawer = false"
            role="option"
          >
            Subscribe
          </v-list-item>
          <v-list-item
            :to="{ name: 'logout' }"
            @click="drawer = false"
            role="option"
          >
            Sign out
          </v-list-item>
        </div>
        <div v-else>
          <v-list-item
            :to="{ name: 'login' }"
            @click="drawer = false"
            role="option"
          >
            Sign in
          </v-list-item>
          <v-list-item
            @click="drawer = false"
            role="option"
            aria-selected="false"
          >
            <v-btn
              v-if="!accountStore.user.subscribed"
              variant="flat"
              color="primary"
              @click="router.push({ name: 'subscriptions' })"
              style="margin-left: 9px"
              class="mt-1 mr-2 ml-0"
              >Subscribe</v-btn
            >
          </v-list-item>
        </div>

        <v-list-item
          @click="goToSearch"
          role="option"
          aria-label="Go to search"
        >
          <v-icon
            variant="text"
            icon="fas fa-magnifying-glass"
            color="black"
            title="Search"
          ></v-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="!isRabbitHole"
      elevation="0"
      class="bg-white"
      :style="appBarStyle"
      height="64"
    >
      <div class="ml-4 ml-lg-4 mt-0">
        <router-link
          :to="{ name: 'home' }"
          title="Home"
        >
          <v-img
            v-if="router.currentRoute.value.name === 'home'"
            :src="df"
            alt="Digital Frontier"
            width="50"
          />
          <v-img
            v-else
            :src="logo"
            alt="Digital Frontier"
            width="140"
            class="mr-10 mr-sm-0"
          />
        </router-link>
      </div>

      <v-spacer class="d-none d-md-inline-block"></v-spacer>

      <div class="top-menu d-none d-lg-flex justify-center align-center">
        <!--        <v-btn-->
        <!--          variant="text"-->
        <!--          rounded-->
        <!--          color="#eee"-->
        <!--          size="small"-->
        <!--          :to="{ name: 'category', params: { category: 'technology' } }"-->
        <!--          class="">-->
        <!--          <template #prepend>-->
        <!--            <img-->
        <!--              src="@/assets/icons/technology_icon.svg"-->
        <!--              alt="Technology Icon"-->
        <!--              width="24"-->
        <!--              height="25" />-->
        <!--          </template>-->
        <!--          Technology-->
        <!--        </v-btn>-->
        <router-link
          :to="{ name: 'category', params: { category: 'technology' } }"
          title="Technology"
        >
          <div class="d-flex justify-center align-center">
            <img
              src="@/assets/icons/technology_icon.svg"
              alt="Technology Icon"
              width="24"
              height="25"
              class="mr-2"
            />TECHNOLOGY
          </div>
        </router-link>
        <!--        <v-btn-->
        <!--          variant="flat"-->
        <!--          rounded-->
        <!--          color="#eee"-->
        <!--          size="small"-->
        <!--          :to="{ name: 'category', params: { category: 'culture' } }"-->
        <!--          class="">-->
        <!--          <template #prepend>-->
        <!--            <img-->
        <!--              src="@/assets/icons/culture_icon.svg"-->
        <!--              alt="Culture Icon"-->
        <!--              width="24"-->
        <!--              height="25" />-->
        <!--          </template>-->
        <!--          Culture-->
        <!--        </v-btn>-->
        <router-link
          :to="{ name: 'category', params: { category: 'culture' } }"
          title="Technology"
        >
          <div class="d-flex justify-center align-center">
            <img
              src="@/assets/icons/culture_icon.svg"
              alt="Culture Icon"
              width="24"
              height="25"
              class="mr-2"
            />CULTURE
          </div>
        </router-link>
        <!--        <v-btn-->
        <!--          prepend-icon="fa-solid fa-eye"-->
        <!--          variant="flat"-->
        <!--          rounded-->
        <!--          color="#eee"-->
        <!--          size="small"-->
        <!--          :to="{ name: 'category', params: { category: 'perspectives' } }"-->
        <!--          class="">-->
        <!--          <template #prepend>-->
        <!--            <img-->
        <!--              src="@/assets/icons/perspectives_icon.svg"-->
        <!--              alt="Perspectives Icon"-->
        <!--              width="25"-->
        <!--              height="24" />-->
        <!--          </template>-->
        <!--          Perspectives-->
        <!--        </v-btn>-->
        <router-link
          :to="{ name: 'category', params: { category: 'perspectives' } }"
          title="Technology"
        >
          <div class="d-flex justify-center align-center">
            <img
              src="@/assets/icons/perspectives_icon.svg"
              alt="Perspectives Icon"
              width="24"
              height="25"
              class="mr-2"
            />PERSPECTIVES
          </div>
        </router-link>
        <!--        <v-btn-->
        <!--          variant="flat"-->
        <!--          rounded-->
        <!--          color="#eee"-->
        <!--          size="small"-->
        <!--          :to="{ name: 'category', params: { category: 'company-building' } }"-->
        <!--          class="">-->
        <!--          <template #prepend>-->
        <!--            <img-->
        <!--              src="@/assets/icons/company_building_icon.svg"-->
        <!--              alt="Company Building Icon"-->
        <!--              width="24"-->
        <!--              height="24" />-->
        <!--          </template>-->
        <!--          Company Building-->
        <!--        </v-btn>-->
        <router-link
          :to="{ name: 'category', params: { category: 'company-building' } }"
          title="Technology"
        >
          <div class="d-flex justify-center align-center">
            <img
              src="@/assets/icons/company_building_icon.svg"
              alt="Company building icon"
              width="24"
              height="25"
              class="mr-2"
            />COMPANY BUILDING
          </div>
        </router-link>
        <div class="text-link-container">
          <div>
            <a
              href="https://digitalfrontier.newsstand.co.uk"
              title="Magazine"
              target="_blank"
              >MAGAZINE</a
            >
            <router-link
              :to="{ name: 'podcast-index' }"
              title="Podcasts"
              >PODCASTS</router-link
            >
            <router-link
              :to="{ name: 'newsletters' }"
              title="Newsletters"
              >NEWSLETTERS</router-link
            >
          </div>
        </div>
      </div>

      <v-spacer class="d-none d-sm-block"></v-spacer>

      <v-btn
        v-if="!accountStore.user.subscribed"
        variant="flat"
        color="black"
        border="black"
        size="small"
        rounded
        @click="router.push({ name: 'subscriptions' })"
        class="d-none d-sm-block ml-3 mr-2 subscribe-button"
        aria-label="Subscribe"
        >Subscribe</v-btn
      >
      <div
        v-if="accountStore.authenticated"
        class="d-none d-md-flex mr-0 mr-md-0 hide-on-collapse"
      >
        <!--          <p>Welcome<br/>{{ accountStore.user.name }}</p>-->

        <v-menu offset="5">
          <template v-slot:activator="{ props }">
            <v-btn
              color="black"
              v-bind="props"
              size="x-small"
              variant="outlined"
              icon="fas fa-user"
              class="mr-0"
              aria-label="Open user menu"
            >
            </v-btn>
          </template>

          <v-list aria-label="Signed in user menu items">
            <v-list-item
              :to="{ name: 'account' }"
              role="option"
            >
              <v-list-item-title>Account</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'bookmarks' }"
              role="option"
            >
              <v-list-item-title>Bookmarks</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'newsletters' }"
              @click="drawer = false"
              role="option"
              ><v-list-item-title>Newsletters</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/logout"
              role="option"
            >
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div
        v-else
        class="mr-0 d-none d-md-flex hide-on-collapse"
      >
        <v-btn
          class="ml-2 mr-0"
          @click="router.push({ name: 'login' })"
          icon="fas fa-user"
          size="x-small"
          variant="outlined"
          aria-label="Open user menu"
        ></v-btn>
        <!--          <v-btn variant="flat" color="primary" @click="router.push({name: 'subscriptions'})">Subscribe</v-btn>-->
      </div>
      <v-btn
        @click="goToSearch"
        variant="flat"
        icon="fas fa-magnifying-glass"
        color="black"
        size="x-small"
        title="Search"
        class="d-none d-sm-block ml-2 mr-4"
        aria-label="Go to search page"
      ></v-btn>

      <v-spacer class="d-sm-none"></v-spacer>
      <v-btn
        v-if="!accountStore.user.subscribed"
        class="d-block d-sm-none subscribe-button"
        variant="flat"
        color="black"
        border="black"
        size="x-small"
        rounded
        aria-label="Subscribe"
        @click="router.push({ name: 'subscriptions' })"
        >Subscribe</v-btn
      >
      <v-spacer class="d-sm-none"></v-spacer>
      <v-app-bar-nav-icon
        color="black"
        variant="text"
        @click.stop="drawer = !drawer"
        class="d-lg-none mr-3"
        aria-label="Open menu"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <div id="error-holder"></div>
    <!--      <v-sheet class="mt-16 d-flex justify-center">-->
    <!--        <p class="text-center mt-10" style="width: 30%"><router-link :to="{ name: 'home'}"><v-img :src="logo" /></router-link></p>-->
    <!--      </v-sheet>-->

    <router-view v-slot="{ Component }">
      <template v-if="Component">
        <transition name="fade">
          <Suspense>
            <template #default>
              <component :is="Component" />
            </template>

            <template #fallback>
              <div class="mt-16 fill-height d-flex justify-center align-center fixed-loader loader-bg">
                <v-progress-circular
                  color="black"
                  indeterminate
                  :size="100"
                  :width="15"
                ></v-progress-circular>
              </div>
            </template>
          </Suspense>
        </transition>
      </template>
    </router-view>
  </v-app>
</template>

<style scoped lang="scss">
  .loader-bg {
    background: var(--main-bg-color) !important;
  }
  .v-overlay.v-menu {
    z-index: 1005 !important;
  }
  .fade-enter-active {
    transition: opacity 0.5s ease-in;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  /* Reserve space for the loader */
  .fixed-loader {
    width: 100%; /* Ensure the container takes full width */
    height: 100%; /* Ensure the container takes full height */
    max-height: 100vh; /* Limit height to viewport height */
    position: fixed; /* Position it fixed, so it won't affect the page layout */
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: Semi-transparent background */
    z-index: 9999; /* Optional: Ensure it overlays on top of other content */
  }

  .text-link-container {
    margin-top: 1px;
    border-left: solid 1px black;
    margin-left: 10px;
    padding-left: 10px;
    div {
      margin-bottom: 3px;
    }
    a {
      padding: 0 10px;
    }
  }
</style>

<style lang="scss">
  .v-navigation-drawer {
    z-index: 3000 !important;
  }
  .subscribe-button {
    border: solid black 1px !important;
    margin-right: 1px;
    font-size: 20px;
    font-weight: 600;
    .v-btn__content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  //.v-toolbar {
  //  z-index: 2001 !important;
  //}

  .v-toolbar__content {
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 1280px) {
      max-width: 1400px !important;
    }
    @media only screen and (min-width: 1920px) {
      max-width: 1600px !important;
    }
  }
</style>
