<script setup>
import {computed} from "vue";

const props = defineProps({ blok: Object, index: Number, centralText: Boolean })

// v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2

const colClasses = computed(() => {
  if (props.blok.width === 'aligned') {
    return 'v-col-12 offset-0'
  }
  else if (props.blok.width === 'inset') {
    return 'v-col-12 offset-0'
  }
  else {
    return 'v-col-12 offset-0'
  }
})

const srcSet = computed(() => {
  let filename = props.blok.image.filename;
  if (props.blok.width === 'aligned') {
    return filename + '/m/960x0 960w,' + filename + '/m/477x0 477w,' + filename + '/m/769x0 769w,' + filename + '/m/886x0 886w'
  }
  else {
    // extended
    return filename + '/m/960x0 960w,' + filename + '/m/477x0 477w,' + filename + '/m/1109x0 1109w,' + filename + '/m/1276x0 1276w'
  }

})

const sizes = computed(() => {
  if (props.blok.width === 'aligned') {
    return '(min-width: 600px) 960px, (min-width: 960px) 477px, (min-width: 1280px) 769px, (min-width: 1920px) 886px, 100vw'
  }
  else {
    //extended
    return '(min-width: 600px) 960px, (min-width: 960px) 477px, (min-width: 1280px) 1109px, (min-width: 1920px) 1276px, 100vw'
  }

})

const src = computed(() => {
  if (props.blok.width === 'aligned') {
    return props.blok.image.filename + '/m/960x0'
  }
  else {
    //extended
    return props.blok.image.filename + '/m/960x0'
  }

})

</script>

<template>

    <div v-if="props.blok.width === 'full'" :class="props.blok.hide_on_mobile ? 'd-none d-sm-block mt-12 mb-12' : 'mt-12 mb-12'">
      <v-img
          :srcset="blok.image.filename + '/m/960x0 960w,' + blok.image.filename + '/m/1400x0 1400w,' + blok.image.filename + '/m/1600x0 1600w'"
          sizes="(min-width: 960px) 960px, (min-width: 1280px) 1400px, (min-width: 1920px) 1600px, 960px"
          :src="blok.image.filename + '/m/960x0'"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          cover
          :alt="blok.image.alt"
          class="mt-6"
      >
      </v-img>
      <v-col v-if="blok.image.title || blok.image.source" :class="'mt-0 pb-4 pl-0 pr-0 ' + (props.centralText ? 'offset-md-2 v-col-md-8' : 'v-col-12 offset-0')">
        <p class="mt-2"><span v-if="blok.image.title" class="copyright">{{ blok.image.title }}</span><span class="credit">{{ blok.image.source }}</span></p>
      </v-col>
    </div>



  <v-container v-else :fluid="true" :class="props.blok.hide_on_mobile ? 'd-none d-sm-block mt-12 mb-12' : 'mt-12 mb-12 pl-3 pr-3 pl-md-4 pr-md-4'">
    <v-row v-if="props.centralText">
      <v-col :class="colClasses">
        <v-row>
          <v-col :class="'pl-0 pr-0 ' + (props.blok.width === 'aligned' ? 'v-col-12 offset-md-2 v-col-md-8' : props.blok.width === 'extended' ? 'v-col-12 offset-md-1 v-col-md-10' : props.blok.width === 'inset' ? 'v-col-12 offset-md-3 v-col-md-6' : 'v-col-12 offset-md-1 v-col-md-10')">
            <v-img
                :srcset="srcSet"
                :sizes="sizes"
                :src="src"
                lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                :alt="blok.image.alt"
            >
            </v-img>
          </v-col>
          <v-col :class="'pt-1 pb-0 pt-md-3 pl-0 pr-0 ' + (props.blok.width === 'aligned' ? 'v-col-12 offset-md-2 v-col-md-8' : props.blok.width === 'extended' ? 'v-col-12 offset-md-1 v-col-md-10' : props.blok.width === 'inset' ? 'v-col-12 offset-md-3 v-col-md-6' : 'v-col-12 offset-md-1 v-col-md-10')">
            <p class="mt-0" v-if="blok.image.title || blok.image.source"><span v-if="blok.image.title" class="copyright">{{ blok.image.title }}</span><span class="credit">{{ blok.image.source }}</span></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col :class="colClasses">
        <v-row>
          <v-col :class="'pl-0 pr-0 pl-md-3 pr-md-3 ' + (props.blok.width === 'aligned' ? 'v-col-12 offset-md-2 v-col-md-8' : (props.blok.width === 'extended' || props.blok.width === 'inset') ? 'v-col-12 v-col-md-9 offset-md-1' : 'v-col-12 v-col-md-10')">
            <v-img
                :srcset="srcSet"
                :sizes="sizes"
                :src="src"
                lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                :alt="blok.image.alt"
            >
            </v-img>
          </v-col>
          <v-col :class="'pt-1 pb-0 pt-md-3 pl-0 pr-0 pl-md-3 pr-md-3 ' + (props.blok.width === 'aligned' ? 'v-col-12 v-col-md-2' : (props.blok.width === 'extended' || props.blok.width === 'inset') ? 'v-col-12 v-col-md-2' : 'v-col-12 v-col-md-2')">
            <p class="mt-0" v-if="blok.image.title || blok.image.source"><span v-if="blok.image.title" class="copyright">{{ blok.image.title }}</span><br><span class="credit">{{ blok.image.source }}</span></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
/*.v-container--fluid {*/
/*  padding: 0 !important;*/
/*}*/
</style>
