<script setup>
  import { useAccountStore } from "@/stores/account";
  import axios from "axios";
  import { onMounted, ref, toRef, computed } from "vue";
  import { useRoute } from "vue-router";

  const props = defineProps({
    isFavourite: Boolean,
    uuid: String,
  });

  const emit = defineEmits(["remove-bookmark"]);
  const route = useRoute();
  const accountStore = useAccountStore();
  const userId = accountStore.user.id;

  const favourite = ref(props.isFavourite);
  console.log("favourite", favourite.value);

  let apiClient = axios.create({
    baseURL: import.meta.env.VITE_API_HOST,
    withCredentials: true,
    withXSRFToken: true,
  });

  const submitFavourite = async () => {
    return apiClient.get("/sanctum/csrf-cookie").then(() => {
      return apiClient
        .post("/api/users/me-favourites", {
          story_uuid: props.uuid,
        })
        .then((response) => {
          // registration successful
          if (response.status === 200) {
            favourite.value = true;
          } else {
            return Promise.reject("not authorised");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  const removeFavourite = async () => {
    return apiClient.get("/sanctum/csrf-cookie").then(() => {
      return apiClient
        .delete("/api/users/me-favourites", { data: { story_uuid: props.uuid } })
        .then((response) => {
          if (response.status === 200) {
            if (route.name === "bookmarks") {
              emit("remove-bookmark", props.uuid);
            } else {
              emit("remove-bookmark", props.uuid);
              favourite.value = false;
            }
          } else {
            // return Promise.reject("not authorised");
          }
        })
        .catch((error) => {
          console.error(error); // Log the error
          return Promise.reject(error); // Reject the promise to propagate the error
        });
    });
  };

  const bookmarkIcon = computed(() => {
    if (route.name === "bookmarks") {
      return "fa-solid fa-xmark";
    }
    return "fa-solid fa-bookmark";
  });

  onMounted(() => {
    console.log("favourite", favourite.value);
  });
</script>

<template>
  <div
    v-if="userId"
    class="d-inline-block"
  >
    <v-btn
      v-if="favourite || favourite === true || isFavourite"
      icon
      size="default"
      density="compact"
      color="transparent"
      variant="text"
      @click="removeFavourite"
    >
      <v-icon
        size="default"
        color="black"
        >{{ bookmarkIcon }}</v-icon
      >
    </v-btn>

    <v-btn
      v-else
      icon
      size="default"
      density="compact"
      color="transparent"
      variant="text"
      @click="submitFavourite"
    >
      <v-icon
        size="small"
        color="black"
        >fa-regular fa-bookmark</v-icon
      >
    </v-btn>
  </div>
</template>

<style scoped></style>
